<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="container">
        <ion-card class="login-card-container">
          <ion-card-header class="login-card-title">
            <ion-card-title>
              <img class="login-petnet-logo" src="@/assets/misc/haya-main-logo-full.png" />
            </ion-card-title>
          </ion-card-header>
          <ion-card-content class="login-card-subtitle">
            <p>Login is required to Continue</p>
            <br>
            <ion-button :disabled="loginButtonLoading" class="ion-activatable login-card-button" color="light" @click="googleLogin">
                <ion-ripple-effect></ion-ripple-effect>
                <img class="login-google-logo" src="@/assets/misc/google-logo.png" contain/>&nbsp;Login with Google
            </ion-button>
            <br>
            <br>
            <br>
            <p>Application v1.0.7</p>
          </ion-card-content>
        </ion-card>
      </div>
      <div class="first-partition"></div>
      <div class="second-partition"></div>
    </ion-content>
  </ion-page>
</template>
<script>
import { useRouter } from 'vue-router';
import { mapGetters } from 'vuex';
import store from '../store/index';
import CryptoJS from 'crypto-js';
import VueCookies from 'vue-cookies'

import {
  IonCardHeader, 
  IonContent, 
  IonCard, 
  IonCardContent, 
  IonCardTitle, 
  IonButton, 
  IonRippleEffect, 
  IonPage, 
  loadingController ,
  toastController
} from "@ionic/vue";

export default {
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      loginButtonLoading: true
    };
  },
  computed: {
    ...mapGetters([
      "login/currUser",
      "login/isAuthenticated"
    ]),
  },
  components: {
    IonCardHeader,
    IonContent,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonButton,
    IonRippleEffect,
    IonPage
  },
  watch:{
    store: {
      handler() {
      },
      deep: true
    },
  },
  async created() {    
    //If token is available, validate
    const that = this;

    if (localStorage.getItem('is_session_expired')) {
      if (localStorage.getItem('is_session_expired') == 'true') {
        const that = this;

        setTimeout(function() {
          that.openToast('Session expired, please re-login', 'danger');
          localStorage.removeItem('is_session_expired');
        });
      }
    }
    
    if (VueCookies.get('misc') === null  || VueCookies.get('misc') === undefined || VueCookies.get('misc') === '') {
      this.loginButtonLoading = false;
    } else {
      await store.dispatch('login/verifyAuth').then(() => {
        if (store.getters['login/isAuthenticated'] === true) {
          let user = '';
          let parsed_user = {};

          this.loginButtonLoading = false;

          if (store.getters['login/currUser']) {
            user = store.getters['login/currUser'];
            parsed_user = JSON.parse(user);

            if (parsed_user.employee_no !== null 
              || parsed_user.employee_no !== ''
              || parsed_user.employee_no !== undefined) {
              that.$router.push('/dashboard')

            } else {
              that.$router.push('/onboard')

            }
          }
        } else {
          VueCookies.remove("misc");
          VueCookies.remove("misc-hs");
          that.loginButtonLoading = false;
        }
      }).catch(() => {
        VueCookies.remove("misc");
        VueCookies.remove("misc-hs");
        that.loginButtonLoading = false;
      }); 
    }
  },
  mounted () {
    window.addEventListener('message', this.onMessage, false);
  },
  beforeUnmount () {
    window.removeEventListener('message', this.onMessage);
  },
  methods: {
    googleLogin () {
      store.dispatch('login/doLoginWithGoogle');
    },
    async dismissLoading() {
      const loading = await loadingController;

      loading.dismiss()
    },
    async presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'loading-container',
          message: 'Please wait...',
          duration: this.timeout,
        });

      await loading.present();
    },
    async openToast(msg, type) {
      const toast = await toastController
          .create({
              message: msg,
              animated: true,
              duration: 5000,
              color: type,
              end: true
          })
      return toast.present();
    },
    onMessage(event) {
      const parentURL = process.env.VUE_APP_API_PARENT_URL || 'https://hayaapi.perahub.com.ph'
      if (event.origin === parentURL) {
        if (event.data.type === "success") {
          this.presentLoading();

          if (VueCookies.get('misc') === null || VueCookies.get('misc') === undefined || VueCookies.get('misc') === '') {
            let userData = {
              user: event.data.user,
              token: event.data.token
            }

            let encryptData = CryptoJS.AES.encrypt(JSON.stringify(userData), process.env.VUE_APP_DCRPT_KEY).toString();
            let encryptHSData = CryptoJS.AES.encrypt(event.data.hs_token, process.env.VUE_APP_DCRPT_KEY).toString();
            
            VueCookies.set("misc", encryptData, '1d', null, null, true, "Strict");
            VueCookies.set("misc-hs", encryptHSData, '1d', null, null, true, "Strict");
            localStorage.removeItem('is_session_expired');

            const that = this;

            setTimeout(function() {
              that.dismissLoading();

              if (event.data.user.employee_no === null 
                || event.data.user.employee_no === ''
                || event.data.user.employee_no === undefined) {
                that.$router.push('/onboard')
              } else {
                that.$router.push('/dashboard')
              }
            }, 5000);
          } else {
            this.dismissLoading();
          }
        } else if (event.data.type === "error") {        
          this.openToast(`<b>${event.data.message}`, 'danger');
        }
      } else {
        return;
      }

      
    }
  }
};
</script>
<style scoped>
  .container {
    position: absolute;
    top: 50%;
    left: 50%; 
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .login-card-container {
    width: 300px;
  }

  .login-card-title {
    text-align: center;
    padding-bottom: 0;
  }

  .login-google-logo {
    height: 25px;
    width: 25px;
  }

  .first-partition,
  .second-partition {
    width: 100%;
    height: 50%;
  }

  .first-partition {
    background: url('~@/assets/wallpaper/contact-tracing.jpg');
    background-size: contain;
  }

  .login-card-subtitle {
    text-align: center;
  }

  .login-card-button {
    width: 100%;
    font-weight: bolder;
    position: relative;
    right: 2px;
  }

  @media (min-width: 426px) {
    .login-card-container {
      width: 400px;
    }
  }
</style>